import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as style from '../modules/presse.module.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import { CalendarDays, MessageCircleMore, UserRound } from 'lucide-react';

const PressMentions = () => {
  const {
    pagePresseYaml: { press },
  } = useStaticQuery(graphql`
    query {
      pagePresseYaml {
        press {
          logo {
            childImageSharp {
              gatsbyImageData(width: 120, placeholder: BLURRED)
            }
          }
          image {
            childImageSharp {
              gatsbyImageData(width: 400, placeholder: BLURRED)
            }
          }
          link
          source
          title
          date
          comments
          author
          description
        }
      }
    }
  `);

  return (
    <Layout withBanner>
      <section className={style.pressMentions}>
        <header className={style.header}>
          <h1 className={style.title}>ON PARLE DE NOUS !</h1>
          <p className={style.subtitle}>Retrouvez BeMum dans la presse</p>
        </header>

        <div className={style.articles}>
          {press.map((article, index) => (
            <div key={index} className={style.article}>
              <div className={style.imageWrapper}>
                <GatsbyImage image={getImage(article.image)} alt={article.title} className={style.articleImage} />
                <GatsbyImage image={getImage(article.logo)} alt={article.source} className={index === 0 ? style.special : index %2 === 0 && index!=0 ? style.logo : style.logoImpaire} />
              </div>
              <div className={style.content}>
                <h2 className={style.source}>{article.source}</h2>
                <h3 className={style.contentTitle}>{article.title}</h3>
                <p className={style.date}>
                  <span>
                    <CalendarDays color={'#333333'} /> {article.date}
                  </span>
                  <span>
                    <MessageCircleMore color={'#333333'} /> {article.comments} commentaires
                  </span>
                  <span>
                    <UserRound color={'#333333'} /> {article.author}
                  </span>
                </p>
                <p className={style.description}>{article.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default PressMentions;
