// extracted by mini-css-extract-plugin
export var article = "presse-module--article--pT4Ki";
export var articleImage = "presse-module--articleImage--R3Sgc";
export var articles = "presse-module--articles--wL8JA";
export var content = "presse-module--content--No7l5";
export var contentTitle = "presse-module--contentTitle--Hdgv+";
export var date = "presse-module--date--qvvp2";
export var description = "presse-module--description--fG-J5";
export var header = "presse-module--header--3G04b";
export var imageWrapper = "presse-module--imageWrapper--qseK0";
export var logo = "presse-module--logo--0WL4a";
export var logoImpaire = "presse-module--logoImpaire--Xjh1W";
export var pressMentions = "presse-module--pressMentions--0INsM";
export var source = "presse-module--source--6G+bO";
export var special = "presse-module--special--4lyJd";
export var subtitle = "presse-module--subtitle--gaEQn";
export var title = "presse-module--title--PE9NZ";